<template>
  <transition name="fade">
    <div class="alert-box-wrapper" v-if="sendshow">
      <!-- 提示领券 -->
      <div class="alert-box" v-if="showType == 1">
        <div class="topimg"><img src="../../assets/getcoupon-title.png" /></div>
        <div class="alert-title">回馈新老用户 停车有位享优惠</div>
        <div class="coupon-list">
          <div class="coupon-item" v-for="(item, index) in dataList" v-show="index < 5">
            <div class="left">
              <div>{{ item.ext1 }}</div>
              <div>{{ item.validityTimeToString }}</div>
            </div>
            <div class="right">{{ couponTypeList[item.type] }}</div>
          </div>
        </div>
        <div class="time">活动时间：{{ startTime }}~{{ endTime }}，限量供应</div>
        <div class="getbtn" @click="handleGetCoupon">
          <div class="clickhand"><img src="../../assets/getcoupon-click.png" /></div>
          点我抢吧
        </div>
        <div class="close" @click="handleClose"><van-icon name="cross" /></div>
      </div>
      <!-- 抢到券 -->
      <div class="acquire-coupon" v-else-if="showType == 2">
        <div class="topimg"><img src="../../assets/getcpupon-top.png" /></div>
        <div class="gettitle">抢到券啦~</div>
        <div class="coupon-list-box">
          <div class="coupon-item" v-for="item in successList">
            <div class="left">
              <div>{{ item.type == 2 ? item.typeToString + `(${item.valueToString})` : item.valueToString }}</div>
              <div>{{ item.validityTimeToString }}</div>
            </div>
            <div class="right">{{ item.typeToString }}</div>
          </div>
        </div>
        <div class="ground-floor"></div>
        <div class="first-floor">
          <div class="getbtn" @click="handleClose">开心收下</div>
          <div class="check">可在“中智停车”小程序 > 优惠券中查看</div>
        </div>
        <div class="close" @click="handleClose"><van-icon name="cross" /></div>
      </div>
      <!-- 未抢到 -->
      <div class="noget" v-else-if="showType == 3">
        <div class="sad"><img src="../../assets/sad.png" /></div>
        <div class="sadtext">很遗憾</div>
        <div class="sadtext" style="font-size: 15px; font-weight: 400">手慢无，停车券被秒光了！</div>
        <van-button round type="info" block class="close" @click="handleClose">关闭窗口</van-button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "GetCouponAlert",
  props: {
    sendshow: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      show: this.sendshow,
      dataList: [],
      startTime: null,
      endTime: null,
      successList: [],
      showType: 1,
      id: null,
      couponTypeList: {
        1: "现金券",
        2: "免单券",
        3: "满减券",
        4: "折扣券",
      },
      domain: process.env.VUE_APP_TESTLOCAL_DOMAIN,
    };
  },
  mounted() {
    this.getCouponList();
  },
  methods: {
    // 抢券
    handleGetCoupon() {
      this.$toast.loading({
        duration: 0,
        message: "抢券中...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let appId = localStorage.getItem("appId");
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      let { id } = this;
      this.$axios
        .post(`${this.domain}/api/opercenter/disposeFocusOfficial`, {
          appId,
          openId,
          id,
        })
        .then(() => {
          this.$axios
            .post(`${this.domain}/api/opercenter/queryVehicleUserHasPhone`, {
              appId,
              openId: openId ? openId : alipayuserId,
            })
            .then((res) => {
              if (res.data.data) {
                this.$toast.fail("请先注册");
              } else {
                this.$axios
                  .post(`${this.domain}/api/opercenter/queryActivityOfH5FeedBack`, {
                    appId,
                    openId: openId ? openId : alipayuserId,
                  })
                  .then((res) => {
                    this.$toast.clear();
                    if (res.data.data !== null && res.data.data.bzEquitiesGoodsList.length) {
                      this.successList = res.data.data.bzEquitiesGoodsList;
                      this.showType = 2;
                    } else {
                      this.showType = 3;
                    }
                  })
                  .catch((e) => {
                    this.$toast.clear();
                  });
              }
            })
            .catch((e) => {
              this.$toast.clear();
            });
        })
        .catch((e) => {
          this.$toast.clear();
        });
    },
    // 列表
    getCouponList() {
      let appId = localStorage.getItem("appId");
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      this.$axios
        .post(`${this.domain}/api/opercenter/queryBzActivityOfParkPay`, {
          appId,
          openId: openId ? openId : alipayuserId,
        })
        .then((res) => {
          if (res.data.data.filter((item) => item.type == 4).length) {
            this.dataList = res.data.data.filter((item) => item.type == 4)[0].bzEquitiesGoodsList;
            this.startTime = res.data.data.filter((item) => item.type == 4)[0].startTime;
            this.endTime = res.data.data.filter((item) => item.type == 4)[0].endTime;
            this.id = res.data.data.filter((item) => item.type == 4)[0].id;
          }
        });
    },
    handleClose() {
      this.$emit("destroyed");
    },
    destroyVm() {
      // 销毁
      this.show = false;
      setTimeout(() => {
        this.$destroy(true);
        this.$el && this.$el.parentNode.removeChild(this.$el);
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.alert-box-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);

  .alert-box {
    position: relative;
    box-sizing: border-box;
    width: 280px;

    .topimg {
      padding: 0 25px;
      // width: 230px;

      img {
        width: 100%;
      }
    }

    .alert-title {
      text-align: center;
      font-size: 15px;
      color: #ffffff;
    }

    .coupon-list {
      .coupon-item {
        display: flex;
        margin-top: 10px;
        width: 100%;
        height: 70px;
        background: url("../../assets/getcoupon-packet.png") no-repeat;
        background-size: 100% 100%;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 70%;
          color: #b45227;

          :nth-child(1) {
            font-size: 18px;
            font-weight: 550;
          }
          :nth-child(2) {
            font-size: 13px;
          }
        }

        .right {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30%;
          background: linear-gradient(135deg, rgb(255, 239, 216), rgb(255, 206, 114));
          -webkit-background-clip: text;
          color: transparent;
          font-size: 20px;
          font-weight: 550;
        }
      }
    }

    .getbtn {
      position: relative;
      margin: 0 auto;
      margin-top: 10px;
      width: 150px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: #8a3a16;
      font-size: 15px;
      background: url("../../assets/getcpupon-btn.png") no-repeat;
      background-size: 100% 100%;

      .clickhand {
        position: absolute;
        right: 0;
        bottom: -20px;
        width: 43px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .time {
      margin-top: 10px;
      text-align: center;
      color: #fff;
      font-size: 12px;
    }

    .btn {
      margin-top: 20px;
      color: #fff;
      font-size: 15px;
      border: none;
      background: linear-gradient(180deg, rgb(255, 167, 99), rgb(250, 114, 12) 100%);
    }

    .close {
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      line-height: 30px;
      border: 1.5px solid rgb(255, 255, 255);
      border-radius: 50%;
      color: #fff;
      text-align: center;
      font-size: 18px;
    }
  }

  .acquire-coupon {
    box-sizing: border-box;
    position: relative;
    padding: 10px 8px;
    width: 286px;
    background: linear-gradient(120.05deg, rgb(255, 229, 202) 11.891%, rgb(255, 243, 229) 90.948%);
    border-radius: 20px;

    .topimg {
      position: absolute;
      top: -65px;
      left: 50%;
      transform: translateX(-50%);
      width: 217px;
      height: 90px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .gettitle {
      margin-top: 26px;
      display: flex;
      justify-content: center;
      font-size: 25px;
      color: #b45227;
      font-weight: bold;
    }

    .coupon-list-box {
      margin-top: 10px;
      margin-bottom: 85px;
      box-sizing: border-box;
      padding: 10px;
      background-color: rgb(255, 199, 168);
      border-radius: 20px;
      height: 280px;
      min-height: 280px;
      overflow-y: auto;

      div:last-child {
        margin-bottom: 0;
      }

      .coupon-item {
        display: flex;
        margin-bottom: 10px;
        width: 100%;
        height: 60px;
        background: url("../../assets/getcoupon-typlistbg.png") no-repeat;
        background-size: 100% 100%;

        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 68%;
          color: #b45227;

          :nth-child(1) {
            font-size: 18px;
            font-weight: 550;
          }
          :nth-child(2) {
            font-size: 13px;
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 32%;
          font-size: 18px;
          background-image: linear-gradient(180deg, rgb(254, 174, 94), rgb(250, 109, 45));
          -webkit-background-clip: text;
          color: transparent;
          font-weight: 550;
        }
      }
    }

    .ground-floor {
      z-index: -1;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      height: 220px;
      background: rgb(254, 160, 93);
      border-radius: 20px;
    }

    .first-floor {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 0px;
      left: 50%;
      transform: translateX(-50%);
      width: 300px;
      height: 131px;
      background: url("../../assets/getcoupon-bottom.png") no-repeat;
      background-size: 100% 100%;

      .getbtn {
        margin-top: 40px;
        width: 150px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        color: #8a3a16;
        font-size: 15px;
        background: url("../../assets/getcpupon-btn.png") no-repeat;
        background-size: 100% 100%;
      }

      .check {
        margin-top: 10px;
        color: #fefbca;
      }
    }

    .close {
      position: absolute;
      bottom: -60px;
      left: 50%;
      transform: translateX(-50%);
      width: 30px;
      height: 30px;
      line-height: 30px;
      border: 1.5px solid rgb(255, 255, 255);
      border-radius: 50%;
      color: #fff;
      text-align: center;
      font-size: 18px;
    }
  }

  .noget {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    width: 280px;
    background: linear-gradient(120.05deg, rgb(255, 229, 202) 4.502%, rgb(255, 243, 229) 97.877%);
    box-shadow: 0px 2px 20px 0px rgba(15, 54, 125, 0.08);
    border-radius: 20px;

    .sad {
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      width: 60px;
      height: 60px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .sadtext {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #b45227;
      font-size: 20px;
      font-weight: 550;
    }

    .close {
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(180deg, rgb(255, 167, 99), rgb(250, 114, 12) 100%);
      color: #fff;
      border-radius: 22px;
      border: none;
    }
  }
}
</style>
